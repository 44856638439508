@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&display=swap");

.portfolio__wrapper {
  padding: 3vw 5vw;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  width: calc(10 * 500px + (10 - 1) * 555px); /* Adjusted for 14 logos */
}

.wrapper{
  display: flex;

  animation: marquee 35s linear backwards infinite;

}
.portfolio__wrapper > .portfolio_items {
  width: 200px;
  height: 95px;
  border-radius: 30px;

  background-clip: content-box;
  background-repeat: no-repeat;

  background-size: cover;
  background-position: center;
  flex-direction: column;
  justify-content:space-around;
  margin: 15px;
}


.p5 {
  background: url(./Portfolio_images/1.jpg);
  background-size: 500px;
}

.p2 {
  background: url(./Portfolio_images/4.jpg);
}
.p3 {
  background: url(./Portfolio_images/3.jpg);
}
.p1 {
  background: url(./Portfolio_images/6.jpg);
}
.p4 {
  background: url(./Portfolio_images/5.jpg);
}
.p8 {
  background: url(./Portfolio_images/8.png);
}
.p12 {
  background: url(./Portfolio_images/12.png);
}
.p10 {
  background: url(./Portfolio_images/10.png);
}
.p9 {
  background: url(./Portfolio_images/18.png);
}

.p13 {
  background: url(./Portfolio_images/13.png);
}
.p14 {
  background: url(./Portfolio_images/14.png);
}
.p15 {
  background: url(./Portfolio_images/15.png);
}
.p16 {
  background: url(./Portfolio_images/16.png);
}
.p11 {
  background: url(./Portfolio_images/17.jpg);
  background-size: -100px;

}

.portfolio_wrapper_button {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.Portfolio_button {
  border: 0;
  outline: none;
  padding: 15px 30px;
  background-color: #ffd044;
  font-size: 45px;
  height: 100px;
  font-family: "Playfair Display", serif;
  transition: 0.3s ease;
  width: 50vw;
  font-weight: bold;
  border-radius: 40px;
}

.Portfolio_button > span {
  color: #c72026;
}

.Portfolio_button:hover {
  cursor: pointer;
}
@media (max-width: 500px) {
  .Portfolio_button {
    font-size: 20px;

    border-radius: 20px;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-3900px); /* Adjust based on total logo width (13 * 300px) */
  }
}
