.carousel {
    width: 500px; /* Adjust the width as needed */
    overflow: hidden;
    position: relative;
  }
  
  .carousel__inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  

  