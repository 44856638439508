@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');

.card__wrapper{
    height: 300px;
    width: 300px;
    background-color: #fff;
    border: 4px solid #FFD044 ;
    border-radius: 10%;
    padding: 10px 30px;
    margin: 20px 20px;
    transition: all .2s ease-in-out;

}
.card 
{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
   
   
}
.card__wrapper:hover {
    transform: scale(1.05);
    box-shadow: 8px 4px 74px -21px rgba(0,0,0,0.7);
    -webkit-box-shadow: 8px 4px 74px -21px rgba(0,0,0,0.7);
    -moz-box-shadow: 8px 4px 74px -21px rgba(0,0,0,0.7);
    background-color:#FFD044 ;
    
    
}
.card:hover > .card__text
{
    color:#c52026;
  
  
}
.card > .card__img
{
    
    width: 100px;
}
.card > .card__img > img{
   
    width: 100%;
}
.card > .card__text 
{
    font-family: 'Roboto Slab', serif;
    color: #d7a100;
    padding: 20px 0 15px 0;   
    font-size: 1.3em;   
    transition: all .2s ease-in-out;
    text-align: center                                                ;
}
.card > .card__description
{
    font-family: 'Poppins', sans-serif;
    padding: 0 9px;
    text-align: center;
    color: rgb(39, 39, 39);
}
@media (max-width: 500px) 
{
    
}
.redirect__button {
    /* Center the content horizontally and vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
    margin-top: -30px;
    /* margin-right: 10%; */
  
    /* Default button styles */
    background-color: #FFD044; /* Default color */
    color: black;
    padding: 10px 20px; /* Adjust padding as needed */
    border: none;
    font-size: 16px; /* Adjust font size as needed */
    cursor: pointer; /* Indicate clickable behavior */
    border-radius: 5px; /* Add rounded corners */
  
    /* Hover effect */
    transition: background-color 0.2s ease-in-out; /* Smooth transition */
  
    /* Remove default button outline on focus */
    outline: none;
  }
  
  .redirect__button:hover {
    background-color: #FFFFFF;
    transform: scale(1.05);
    box-shadow: 8px 4px 74px -21px rgba(0,0,0,0.7);
    -webkit-box-shadow: 8px 4px 74px -21px rgba(0,0,0,0.7);
    -moz-box-shadow: 8px 4px 74px -21px rgba(0,0,0,0.7);
    border: 4px solid #FFD044 ;
  }
  

